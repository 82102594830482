import React from 'react';

import { Container, Icon, HeartIcon, FamilyIcon, Title, Description } from './styles';

interface ServiceInterface{
    Icon : string;
    title: string;
    description: string;
}

const CardService = ({service} : {service : ServiceInterface}) => {
    return (
        <Container>
            <Icon urlICon={service.Icon} />
            <Title>{service.title}</Title>
            <Description>{service.description}</Description>
        </Container>
    )
}

export default CardService;