import React from 'react';
import styled from 'styled-components';

import Arrow from '../../assets/icons/blueArrow.svg';

export const ArrowUpIcon = styled.div`
  width: 35px;
  height: 35px;
  background-color: transparent;
  background-image: url(${Arrow});
  background-size: cover;
  transform: rotate(-270deg);
  cursor: pointer;
`;

export const ArrowDownIcon = styled.div`
  width: 35px;
  height: 35px;
  background-color: transparent;
  background-image: url(${Arrow});
  background-size: cover;
  transform: rotate(-90deg);
  cursor: pointer;
`;