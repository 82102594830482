import styled from 'styled-components';

import media from '../../styles/utils/media';

import Balance from '../../assets/icons/blueBalanceIcon.svg';
import Heart from '../../assets/icons/blueHeartIcon.svg';
import Family from '../../assets/icons/blueFamilyIcon.svg';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content:center;

	width: 225px;
	height: 225px;
	padding: 10px;
	margin: 30px 0 0 8px;
	background-color: ${(props) => props.theme.colors.background};
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));

	${media.sm`
		width: 180px;
		height: 180px;
		padding: 10px;
		margin: 30px 0 0 35px;
	`};	

	${media.md`
		width: 225px;
		height: 225px;
		padding: 20px;
		margin: 30px 0 0 8px;
		margin-left: 35px;
	`};	

	${media.lg`
		margin-left: 55px;
		margin-bottom: 30px;
	`}
`;
export const Icon = styled.div<{urlICon : string}>`
	width: 20%;
	height: 25%;
	background-image: url(${(props)=>props.urlICon});
	background-size:100%;
	background-repeat: no-repeat;
	object-fit: cover;
	margin-bottom: 5px;

	${media.sm`

	`}

	${media.md`


	`}
`;

export const Title = styled.h2`
	overflow:hidden;
	color: ${(props) => props.theme.colors.textTitle};
	/*font-size: ${(props) => props.theme.fontSize.S6};*/
	margin: 0;

	${media.sm`
		/*font-size: 1.55rem;*/
	`}

	${media.md`
		/*font-size: ${(props) => props.theme.fontSize.S6};*/
	`}
`;

export const Description = styled.p`
	
	overflow:hidden;
	text-align: justify;
	color: ${(props) => props.theme.colors.textP};
	font-size: ${(props) => props.theme.fontSize.SP}

	${media.sm`
		font: 1.5rem;
	`}

	${media.md`
		font-size: ${(props) => props.theme.fontSize.SP}
	`}
`;