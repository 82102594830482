import React from 'react';
import {graphql, PageProps} from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import { H1 ,P} from '../styles/Fonts';
import { Container, Logo, ServiceCards, ArrowUpIcon, ArrowDownIcon, ContactButton, Description, MobileService } from '../styles/pages/service-page.style';
import CardService from '../components/CardService';
import Navbar from '../components/NavBar';

import Carousel from '../components/ServiceCarousel';

export interface PageServiceProps {
    markdownRemark :{
        frontmatter : ServiceBaseProps
    }
}
export interface ServiceBaseProps  {
    titleSEO : string;
    descriptionSEO: string;
    description :string;
    services : Array<{
        Icon : string;
        title: string;
        description: string;
    }>

}
interface ServiceProps extends ServiceBaseProps {
    location : {pathname : string;};
    preview? : boolean;
}

export  function PageServiceTemplate({ titleSEO ,descriptionSEO,description,location,services,preview  } :ServiceProps ){
    const serviceIsValid = Array.isArray(services) && services != undefined
    let content = undefined;
    if(!serviceIsValid){
        content = (<P  size={'S4'} color={'primary'}> No Momento Não temos nenhum Serviço cadastrado 😥</P>)
    }else{
        content = services.map((service,index)=>(
            <CardService key={`${service.title} - ${index}`} service={service} />

        ));
    }
return (<>
      {!preview?(<SEO title={titleSEO} description={descriptionSEO} pathname={location.pathname} />) :''}

		<Container>
            <Description>{description}</Description>
            

            <Carousel services={services} />

            <ServiceCards>
                {content}
            </ServiceCards>
            
		</Container>
        <Logo />
    </>);}

const  PageService = ({data,location} :PageProps<PageServiceProps>)=>{
    const content = data.markdownRemark.frontmatter;
 return(
     <PageServiceTemplate
     titleSEO={content.titleSEO}
     descriptionSEO={content.descriptionSEO}
     description={content.description}
     services={content.services}
     location={location}
     />
 );
 }
export default PageService;

export const query = graphql`
    query ServicePageQuery {
    markdownRemark(frontmatter: {templateKey: {eq: "service-page"}}) {
        frontmatter {
            titleSEO 
            descriptionSEO
            description         
            services {
                Icon 
                title
                description
            }
        }
    }
    }
`;