import React from "react";
import styled from 'styled-components';
import media from '../utils/media';

import LogoIcon from '../../assets/icons/coloredLogo.png';
import BackgroundImage from '../../assets/images/backgroundService.png';
import Arrow from '../../assets/icons/greyArrow.svg';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;    

    ${media.sm`
        width: 100%;
        height: 100vh;  

        background-image: url(${BackgroundImage});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 100px;
    `}
`;

export const Description = styled.h2`
    width: 100%;
    margin-top: 50px;
    color: ${(props) => props.theme.colors.textP};
    font-family: ${(props) => props.theme.fontFamily.roboto};
    font-size: ${(props) => props.theme.fontSize.S5};
    font-weight: 400;
    text-align: center;
    
    ${media.sm`
        display: none;
    `}

    ${media.md`
        display: none;
    `}

    ${media.lg`
        display: none;
    `}

    ${media.xlg`
        display: none;
    `}
`;

export const ServiceCards = styled.div`
    display: none;

    ${media.sm`
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        margin-left: 25px;
    `}

    ${media.md`
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        margin-left: 45px;
    `}

    ${media.lg`
        display: grid;
        grid-template-columns: auto auto auto;
        align-items: center;
    `}

    ${media.xlg`
    
    `}
`;

export const MobileService = styled.div`
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
`;

export const ArrowUpIcon = styled.div`
    width: 35px;
    height: 35px;
    margin-left: 20px;
    background-color: transparent;
    background-image: url(${Arrow});
    cursor: pointer;
    border: none;
    outline: none;

    ${media.sm`
        
    `}

    ${media.md`
        
    `}

    ${media.sm`
        display: none;
    `}
`;

export const ArrowDownIcon = styled.div`
    width: 35px;
    height: 35px;
    margin-left: 20px;
    background-color: transparent;
    background-image: url(${Arrow});
    transform: rotate(-180deg);
    cursor: pointer;
    border: none;
    outline: none;

    ${media.sm`
        
    `}

    ${media.md`
        
    `}

    ${media.sm`
        display: none;
    `}
`;

export const ContactButton = styled.button`
    width: 300px;
    height: 55px;
    border-radius: 100px;
    border: none;
    outline: none;
    background-color: ${(props) => props.theme.colors.primary};
    margin-top: 30px;
    
    > p {
        margin: 0;
        color: ${(props) => props.theme.colors.background};
        font-family: ${(props) => props.theme.fontFamily.roboto};
        font-size: ${(props) => props.theme.fontSize.S6}
    }

    ${media.md`
        
    `}

    ${media.sm`
        display: none;
    `}
`;

export const Logo = styled.div`
    display: none;

    ${media.sm`
        position: absolute;
        top: calc(100% - 70px);
        left: calc(100% - 60px);
        width: 37.42px;
        height: 40px;
        background-image: url(${LogoIcon});
    `}
`;
