import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';

import 'pure-react-carousel/dist/react-carousel.es.css';

import './styles.css';

import { ArrowUpIcon, ArrowDownIcon } from './styles.ts';
import CardService from '../../components/CardService';
import { Server } from 'http';
import { P } from '../../styles/Fonts';

interface ServiceInterface{
    Icon : string;
    title: string;
    description: string;
}


export default function Carrousel({services } : {services : Array<ServiceInterface>}){
  const serviceIsValid = Array.isArray(services) && services != undefined
  if(!serviceIsValid){
    return ( <div></div> );
  }
  
  return (
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={125}
        totalSlides={Array.isArray(services)?services.length: 0}
        orientation="vertical"
        className="carouselProviderContainer"
      >
        <ButtonBack className="buttonBackStyle">
          <ArrowUpIcon />
        </ButtonBack>
        <Slider className="sliderStyle" >
          {services.map((service,index)=>(
            <Slide key={index} index={index + 1}>
               <CardService service={service} />
             </Slide>
          ))}
        </Slider>
        <ButtonNext className="buttonBackStyle">
          <ArrowDownIcon />
        </ButtonNext>
      </CarouselProvider>
    );
  
}